var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("海外纠纷申报与受理")]),
    _c("div", { staticClass: "sep" }),
    _c("div", { staticClass: "content" }, [
      _c("p", { staticClass: "medium" }, [
        _vm._v(
          "         在长沙知识产权保护中心和中国（湖南）自由贸易试验区知识产权服务中心均设有海外维权援助窗口，负责答复海外维权等咨询，接受海外知识产权纠纷应对指导申请、维权援助申请等。 "
        ),
        _c("br"),
        _c("br")
      ]),
      _c("p", { staticClass: "big" }, [_vm._v("长沙知识产权保护中心")]),
      _c("p", { staticClass: "little" }, [
        _vm._v(" 窗口地址：长沙市高新区麓谷企业广场B8栋广电计量大厦6楼"),
        _c("br"),
        _vm._v(" 咨询电话：0731-82275655"),
        _c("br"),
        _vm._v(" 官方网站：http://csipo.changsha.gov.cn/zscqbhzx/"),
        _c("br"),
        _vm._v(" 微信公众号：长沙知识产权保护中心 csbhzx "),
        _c("br"),
        _c("br")
      ]),
      _c("p", { staticClass: "big" }, [
        _vm._v("中国（湖南）自由贸易试验区知识产权服务中心")
      ]),
      _c("p", { staticClass: "little" }, [
        _vm._v(" 窗口地址： 长沙市长沙县东六路科技新城C7栋4楼"),
        _c("br"),
        _vm._v(" 咨询电话：0731-86394575"),
        _c("br"),
        _vm._v(" 微信公众号：自贸知识产权 "),
        _c("br"),
        _c("br")
      ]),
      _c("span", { staticClass: "little" }, [_vm._v("线上申请：")]),
      _c(
        "a",
        {
          attrs: {
            href: "http://www.worldipguidance.cn/apply/process.html",
            target: "_blank"
          }
        },
        [_vm._v("http://www.worldipguidance.cn/apply/process.html")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }