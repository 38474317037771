<template>
  <el-main class="main">
    <div class="title">海外纠纷申报与受理</div>
    <div class="sep" />
    <div class="content">
      <p class="medium">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在长沙知识产权保护中心和中国（湖南）自由贸易试验区知识产权服务中心均设有海外维权援助窗口，负责答复海外维权等咨询，接受海外知识产权纠纷应对指导申请、维权援助申请等。
        <br />
        <br />
      </p>
      <p class="big">长沙知识产权保护中心</p>
      <p class="little">
        窗口地址：长沙市高新区麓谷企业广场B8栋广电计量大厦6楼<br />
        咨询电话：0731-82275655<br />
        官方网站：http://csipo.changsha.gov.cn/zscqbhzx/<br />
        微信公众号：长沙知识产权保护中心 csbhzx
        <br />
        <br />
      </p>
      <p class="big">中国（湖南）自由贸易试验区知识产权服务中心</p>
      <p class="little">
        窗口地址： 长沙市长沙县东六路科技新城C7栋4楼<br />
        咨询电话：0731-86394575<br />
        微信公众号：自贸知识产权
        <br />
        <br />
      </p>
      <span class="little">线上申请：</span>
      <a href="http://www.worldipguidance.cn/apply/process.html" target="_blank"
        >http://www.worldipguidance.cn/apply/process.html</a
      >
    </div>
  </el-main>
</template>

<script>
import store from "@/store";

export default {
  name: "apply",
  beforeRouteEnter(to, from, next) {
    store.commit("service/SET_ACTIVEINDEX", { activeIndex: "2" });
    next();
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    font-size: 16px;
    font-family: PingFang-SC;
    font-weight: 400;
    color: #222222;
    line-height: 28px;
    margin-top: 22px;

    .little {
      font-size: 15px;
      font-family: PingFang-SC;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }

    .medium {
      font-size: 16px;
      font-family: PingFang-SC;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }

    .big {
      font-size: 17px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #222222;
      line-height: 24px;
    }

    a {
      font-size: 16px;
      font-family: PingFang-SC;
      font-weight: 400;
      line-height: 24px;
      color: #2C53B0;
      text-decoration: none;
    }
  }
}
</style>